import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from 'components/shared/SEO';
import HeaderMini from 'components/Header/HeaderMini';
import TestimonialsGrid from 'components/Testimonials/Grid';

const Wrapper = styled.div`
  margin: 0 0 170px 0;

  @media screen and (max-width: 992px) {
    margin: 0 0 90px 0;
  }
`;

const TestimonialsPage = ({ data }) => {
  const PAGE = data.wpPage.PageTestimonials;

  const testimonialsTiles = PAGE.testimonialsList.map(
    ({ testimonialsListContent, testimonialsListAuthor }) => ({
      content: testimonialsListContent,
      author: testimonialsListAuthor,
    })
  );

  return (
    <>
      <SEO title="Nasi klienci" />
      <Wrapper>
        <HeaderMini
          maxWidth="900px"
          spacing
          center
          subheading={PAGE.testimonialsBannerSubheading}
          heading={PAGE.testimonialsBannerHeading}
          text={PAGE.testimonialsBannerText}
        />
        <TestimonialsGrid tiles={testimonialsTiles} />
      </Wrapper>
    </>
  );
};

TestimonialsPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryPageTestimonials {
    wpPage(slug: { eq: "nasi-klienci" }) {
      PageTestimonials {
        testimonialsBannerHeading
        testimonialsBannerSubheading
        testimonialsBannerText
        testimonialsList {
          testimonialsListAuthor
          testimonialsListContent
        }
      }
    }
  }
`;

export default TestimonialsPage;
