import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TextLarge } from 'components/shared/Typography';

import quoteIcon from 'assets/icons/quote.svg';

const Wrapper = styled.figure`
  background-color: #fff;
  padding: 50px;
  height: 100%;
`;

const Content = styled(TextLarge).attrs({
  as: 'blockquote',
})`
  position: relative;
  padding: 35px 0 0 0;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-image: url(${quoteIcon});
    background-repeat: no-repeat;
    background-size: 20px;
  }
`;

const Author = styled(TextLarge).attrs({
  as: 'figcaption',
})`
  margin: 45px 0 0 0;
  font-weight: 700;
  text-transform: uppercase;
`;

const TestimonialsTile = ({ content, author }) => {
  return (
    <Wrapper>
      <Content>{content}</Content>
      <Author>{author}</Author>
    </Wrapper>
  );
};

TestimonialsTile.propTypes = {
  content: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default TestimonialsTile;
