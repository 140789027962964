import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Container from 'components/shared/Container';
import { HeadingFour, HeadingThree, Text } from 'components/shared/Typography';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';

const Wrapper = styled.section`
  position: relative;
  margin: ${({ spacing }) => (spacing ? '0 0 -140px 0' : '0')};
  padding: 200px 0;
  background-color: ${({ theme }) => theme.colors.blueVariant};

  ${({ background }) =>
    background &&
    css`
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(90deg, #00102e, #00102e38);
        z-index: 1;
      }
    `}

  ${({ spacing }) =>
    spacing &&
    css`
      @media screen and (max-width: 992px) {
        padding: 100px 0 200px 0;
      }
    `}

    ${({ spacing }) =>
    !spacing &&
    css`
      @media screen and (max-width: 992px) {
        padding: 100px 0 100px 0;
      }
    `}
`;

const Subheading = styled(Text)`
  margin: 0 0 10px 0;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

const Heading = styled(HeadingThree)`
  color: #fff;
  line-height: 1.5;
`;

const Content = styled.div`
  position: relative;
  margin: ${({ center }) => (center ? '0 auto' : '0')};
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  z-index: 2;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
`;

const Textblock = styled(Text)`
  margin: 40px 0 0 0;
  padding: ${({ center }) => (center ? '0 110px' : '0')};
  color: ${({ theme }) => theme.colors.lightOpacity};

  @media screen and (max-width: 992px) {
    padding: 0 !important;
    margin: 20px 0 0 0;
  }
`;

const Background = styled.div`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const CtaList = styled.ul`
  margin: 50px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const CtaIcon = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px 0 0;
`;

const CtaContent = styled(HeadingFour).attrs({
  as: 'a',
})`
  color: ${({ theme }) => theme.colors.greenVariant};
`;

const CtaItem = styled.li`
  margin: 0 0 15px 0;
  padding: 0;
  display: flex;
  align-items: center;
  &:nth-of-type(2n) {
    & ${CtaContent} {
      color: ${({ theme }) => theme.colors.lightOpacity};
    }
  }
  &:last-of-type {
    margin: 0;
  }
`;

const HeaderMini = ({
  center,
  maxWidth,
  spacing,
  cta,
  subheading,
  heading,
  text,
  background,
}) => {
  return (
    <Wrapper role="banner" spacing={spacing} background={background}>
      {background && (
        <Background>
          <LazyImage src={background} cover alt="" />
        </Background>
      )}
      <Container>
        <Content maxWidth={maxWidth} center={center}>
          {subheading && <Subheading center={center}>{subheading}</Subheading>}
          <Heading center={center}>{heading}</Heading>
          {text && <Textblock center={center}>{text}</Textblock>}
          {cta && (
            <CtaList>
              {cta.map((item, index) => (
                <CtaItem key={index}>
                  <CtaIcon>
                    <Icon size={20} src={item.icon} alt="" />
                  </CtaIcon>
                  <CtaContent href={item.link}>{item.content}</CtaContent>
                </CtaItem>
              ))}
            </CtaList>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
};

HeaderMini.propTypes = {
  spacing: PropTypes.bool,
  center: PropTypes.bool,
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  maxWidth: PropTypes.string,
  background: PropTypes.objectOf(PropTypes.object),
  cta: PropTypes.objectOf(PropTypes.string),
};

HeaderMini.defaultProps = {
  center: false,
  subheading: null,
  text: null,
  background: null,
  cta: null,
  spacing: false,
  maxWidth: '100%',
};

export default HeaderMini;
