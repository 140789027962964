import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col } from 'styled-bootstrap-grid';
import Container from 'components/shared/Container';
import TestimonialsTile from 'components/Testimonials/Tile';

const Wrapper = styled.section`
  & ${Col} {
    margin-bottom: 30px !important;
  }

  @media screen and (max-width: 992px) {
    & ${Col} {
      margin-bottom: 20px !important;
    }
  }
`;

const TestimonialsGrid = ({ tiles }) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          {tiles &&
            tiles.map((item, index) => (
              <Col lg={6} key={index}>
                <TestimonialsTile
                  margin
                  key={index}
                  content={item.content}
                  author={item.author}
                />
              </Col>
            ))}
        </Row>
      </Container>
    </Wrapper>
  );
};

TestimonialsGrid.propTypes = {
  tiles: PropTypes.oneOfType([PropTypes.string]).isRequired,
};

export default TestimonialsGrid;
